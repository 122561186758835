import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from 'axios'

import { createProject, createTermin, Project, sortTermins, Termin } from '../type'
import { useUserStore } from './userStore'
import { warn } from 'console'
import { useSnackbarStore } from './snackbarStore'
import axiosRetry from 'axios-retry'

axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay, // Exponential back-off
  retryCondition: axiosRetry.isRetryableError,
  onRetry: (retryCount, error, requestConfig) => useSnackbarStore.getState().showSnackbar("danger", "Terminov ni mogoče naložiti: " + error.message)
})

// TERMINS

type calendarStore = {
  calendarView: boolean
  setCalendarView: (b: boolean) => void
  termins: Termin[]
  fetchTermins: () => void
}

export const useCalendarStore = create<calendarStore>()(((set, get) => ({
  calendarView: false,
  setCalendarView: (newValue: boolean) => {
    set({calendarView: newValue})
  },
  termins: [],

  fetchTermins: async () => {
    await axios.get(`https://hudniki.pythonanywhere.com/endpoints/termins/`).then((response) => {
        set({
          termins: response.data
        })
    }).catch((error) => {
      //get().fetchTermins()
      useSnackbarStore.getState().showSnackbar("danger", "Prišlo je do napake pri nalaganju terminov: " + error.code)
      console.log(error)
    })
  },
})))


