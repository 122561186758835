import { useTerminsStore } from "./terminsStore";
import { create } from "zustand";
import axios from "axios";
import { devtools } from "zustand/middleware";
import { useSnackbarStore } from "./snackbarStore";
import { useUserStore } from "./userStore";

export type Participation = {
  id: number;
  participant: number;
  project: number;
};

export type Participant = {
  id: number;
  name: String;
  email: String;
};

type participationStore = {
  participations: Participation[];
  participants: Participant[];
  clear: () => void;
  fetchParticipations: () => void;
  fetchParticipant: (id: number) => void;
  postNewPaticipation: (participant_id: number, project_id: number) => void;
  deleteParticipation: (id: number) => void;
  loading: boolean;
};

export const useParticipationStore = create<participationStore>()(
  devtools((set, get) => ({
    participants: [],
    participations: [],
    clear: () => {
      set({
        participants: [],
        participations: [],
      });
    },
    fetchParticipations: async () => {
      let project_id = useTerminsStore.getState().project_id;
      set({
        participants: [],
        participations: [],
        loading: true,
      });
      await axios
        .get(
          `https://hudniki.pythonanywhere.com/endpoints/participations?project=${project_id}`,
          {
            //headers: { 'Authorization': `Token ${useUserStore.getState().token}` }
          },
        )
        .then(async (res) => {
          set({ participations: res.data });
          for (let participation of res.data) {
            let id = participation.participant;
            get().fetchParticipant(id);
          }
        })
        .then(() => set({ loading: false }))
        .catch((err) => {
          console.log(err);
          set({ loading: false });
        });
    },
    fetchParticipant: async (id: number) => {
      await axios
        .get(`https://hudniki.pythonanywhere.com/endpoints/participants/${id}`)
        .then((res) => {
          let participant: Participant = res.data;
          let temp = get().participants;
          if (!get().participants.some((p) => p.id === participant.id)) {
            temp.push(participant);
            set({ participants: [...temp] });
          }
        })
        .catch((err) => console.log(err));
    },
    postNewPaticipation: async (participant_id: number, project_id: number) => {
      set({ loading: true });
      const token = useUserStore.getState().token;
      await axios
        .post(
          "https://hudniki.pythonanywhere.com/endpoints/participations/",
          {
            participant: participant_id,
            project: project_id,
          },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          },
        )
        .then((response) => {
          useSnackbarStore
            .getState()
            .showSnackbar("success", "Udeleženec uspešno dodan!");
          set({ loading: false });
          get().fetchParticipations();
        })
        .catch((error) => {
          console.log(error);
          useSnackbarStore
            .getState()
            .showSnackbar(
              "danger",
              "Pri dodajanju udeležnenca je prišlo do napake.",
            );
          set({ loading: false });
        });
    },
    deleteParticipation: async (participant_id: number) => {
      const participation = get().participations.find(
        (p) => p.participant === participant_id,
      );
      let token = useUserStore.getState().token;
      if (participation !== undefined) {
        await axios
          .delete(
            "https://hudniki.pythonanywhere.com/endpoints/participations/" +
              participation.id,
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            },
          )
          .then((res) => {
            get().fetchParticipations();
            useSnackbarStore
              .getState()
              .showSnackbar("success", "Udeleženec uspešno odstranjen!");
          })
          .catch((err) => {
            get().fetchParticipations();
            useSnackbarStore
              .getState()
              .showSnackbar("danger", "Napaka pri odstranjevanju udeleženca!");
          });
      } else {
        console.log(
          "Could not find participant with id " +
            participant_id.toString() +
            " among project participations.",
        );
        console.log(get().participations);
      }
    },
    loading: false,
  })),
);
