import * as React from 'react';
import Button from '@mui/joy/Button';
import Snackbar from '@mui/joy/Snackbar';
import { keyframes } from '@mui/system';
import { useSnackbarStore } from '../store/snackbarStore';
import { useShallow } from 'zustand/react/shallow';

export default function SnackbarInfo() {
  const { open, setOpen, color, info } = useSnackbarStore(
  useShallow((state) => ({
    open: state.open,
    setOpen: state.setOpen,
    color: state.color,
    info: state.info,
  }))
  )


  const animationDuration = 600;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        color={color}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
        autoHideDuration={4000}
        animationDuration={animationDuration}
      >
        {info}
      </Snackbar>
    </div>
  );
}
