import { easeOut } from "framer-motion"

// INITIAL
export const initial_grow={
           opacity: 0,
           originY: 0,
           scaleY: 0.7
  }
export const initial_fadeIn = {
  opacity: 0.7
}

// ANIMATE
export const animate_future={
    opacity:1,
    originY: 1,
    scaleY: 1
}
export const animate_past={
  opacity: 0.6,
  originY: 1,
  scaleY: 1,
  backgroundColor: 'rgba(227, 227, 227, 0.25)'
}
export const exit_shrink={
      opacity: 0,
      originY: 0,
      scaleY: 0.3,
      transition: {duration: 0.2, ease: easeOut}
  }
export const exit_fadeOut = {opacity: 0.7}

// TRANSITION
export const transition_slow={
    duration: 0.2
  }
export const transition_fast = {
  duration: 0.05
}

