import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import axios from 'axios'

import { createProject, createTermin, Project, sortProjects, Termin } from '../type'
import { useUserStore } from './userStore'
import { useSnackbarStore } from './snackbarStore'
import { useIndividualTerminStore, useTerminsStore } from './terminsStore'
import axiosRetry from 'axios-retry'
import { warning } from '@remix-run/router/dist/history'
import { useState } from 'react'

// AXIOS RETRY

axiosRetry(axios, {
  retries: 20,
  retryDelay: axiosRetry.exponentialDelay, // Exponential back-off
  retryCondition: axiosRetry.isRetryableError,
  onRetry: (retryCount, error, requestConfig) => useSnackbarStore.getState().showSnackbar("danger", "Napaka: " + error.message)
})

// PROJECTS

type projectsStore = {
  projects: Project[]
  fetchProjects: () => void
  addProject: (project: Project) => void
  removeProject: (project_id: number) => void
  loading: boolean
}

export const useProjectsStore = create<projectsStore>()((set, get) => ({
  projects:[],

  fetchProjects: async () => {
    set({ loading: true })
    await axios.get('https://hudniki.pythonanywhere.com/endpoints/projects/', { 
      headers: {
            'Authorization': (useUserStore.getState().loggedIn) ? `Token ${useUserStore.getState().token}` : ''
        }
        }).then((response) => {
          set({ projects: response.data })
          const sortedProjects = sortProjects(get().projects)
          set({ projects: sortedProjects, loading: false })
        }).catch((err) => {
          useSnackbarStore.getState().showSnackbar("danger", "Prišlo je do napake pri nalaganju projektov: " + err.code)
          get().fetchProjects()
          console.log(err)
        })
      set({ loading: false })
      if (useProjectsStore.getState().projects.length !== 0 && useTerminsStore.getState().project_id === -1) {
        useTerminsStore.setState({
          project_id : useProjectsStore.getState().projects[0].id,
          project_name : useProjectsStore.getState().projects[0].name,
        })
    }
        },

  addProject: async (project) => {
    set({ loading: true })
    await axios.post('https://hudniki.pythonanywhere.com/endpoints/projects/', {
        'name': project.name,
        'owner': useUserStore.getState().userId,
        'end_date': project.end_date,
        'public': project.public_view,
      }, {
        headers: {
            'Authorization': `Token ${useUserStore.getState().token}`
        }
      }).then(
        (response) => {
          useSnackbarStore.getState().showSnackbar('success', 'Nov projekt uspešno dodan');
          useTerminsStore.getState().setProjectId(response.data.id)
          useTerminsStore.getState().setProjectName(response.data.name)
          get().fetchProjects()
          useTerminsStore.setState({
            project_id : response.data.id,
            project_name : response.data.name,
          })
        }
      ).catch(
        (error) => {
          useSnackbarStore.getState().showSnackbar('danger', 'Napaka pri dodajanju projekta: ' + error.message);
          console.log(error)
        }
      );
    set({ loading: false })
  },

  removeProject: async (project_id: number) => {
    set({ loading: true })
    await axios.delete(`https://hudniki.pythonanywhere.com/endpoints/projects/${project_id}`,
                       { headers: { 'Authorization': `Token ${useUserStore.getState().token}` }})
      .then((response) => {
        useSnackbarStore.getState().showSnackbar('warning', `Projekt '${useTerminsStore.getState().project_name}' uspešno odstranjen!`);
        useTerminsStore.getState().setProjectId(-1)
      })
      .catch((err) => {
        useSnackbarStore.getState().showSnackbar('danger', 'Napaka pri odstranjevanju projekta: ' + err.message);
        console.log(err)
      })
    await get().fetchProjects()
    if (get().projects.length !== 0) {
        useTerminsStore.setState({
          project_id : useProjectsStore.getState().projects[0].id,
          project_name : useProjectsStore.getState().projects[0].name,
        })
    } else {
      useTerminsStore.setState({
          project_id : -1,
          project_name : "project__name",
      })
    }
    set({ loading: false })
  },

  loading: false,
}))

type IndividualProjectStore = {
  project: Project | null
  clearProject: () => void
  setProject: (project: Project) => void
  modalAction: () => void
  addProject: () => void
  updateProject: () => void
  editing: boolean
  setEditing: (editing: boolean) => void
  loading: boolean
}

export const useIndividualProjectStore = create<IndividualProjectStore>()((set, get) => ({
  project: null,
  clearProject: () => {
    set({ project: null })
  },
  setProject: (project: Project) => {
    set({project: project})
  },
  modalAction: async () => {
    if (get().editing) {
      await get().updateProject()
    } else {
      await get().addProject()
    }
  },
  addProject: async () => {
    set({ loading: true })
    const project = get().project
    if (project === null) {
      console.log("failed to set individual project")
    } else {
    await axios.post('https://hudniki.pythonanywhere.com/endpoints/projects/', {
        'name': project.name,
        'owner': useUserStore.getState().userId,
        'end_date': project.end_date,
        'public_view': project.public_view,
      }, {
        headers: {
            'Authorization': `Token ${useUserStore.getState().token}`
        }
      }).then(
        (response) => {
          useSnackbarStore.getState().showSnackbar('success', 'Nov projekt uspešno dodan');
          useTerminsStore.getState().setProjectId(response.data.id)
          useTerminsStore.getState().setProjectName(response.data.name)
          useProjectsStore.getState().fetchProjects()
          useTerminsStore.setState({
            project_id : response.data.id,
            project_name : response.data.name,
          })
        }
      ).catch(
        (error) => {
          useSnackbarStore.getState().showSnackbar('danger', 'Napaka pri dodajanju projekta: ' + error.message);
          console.log(error)
        }
      );
    }
    set({ loading: false })
  },

  updateProject: async () => {
  set({ loading: true })
    const project = get().project
    if (project === null) {
      console.log("failed to set individual project")
    } else {
    await axios.patch(`https://hudniki.pythonanywhere.com/endpoints/projects/${project.id}/`, {
        'name': project.name,
        'end_date': project.end_date,
        'public_view': project.public_view,
      }, {
        headers: {
            'Authorization': `Token ${useUserStore.getState().token}`
        }
      }).then(
        (response) => {
          useSnackbarStore.getState().showSnackbar('success', `Projekt '${project.name}' uspešno posodobljen!`);
          useTerminsStore.getState().setProjectId(response.data.id)
          useTerminsStore.getState().setProjectName(response.data.name)
          useProjectsStore.getState().fetchProjects()
          useTerminsStore.setState({
            project_id : response.data.id,
            project_name : response.data.name,
          })
        }
      ).catch(
        (error) => {
          useSnackbarStore.getState().showSnackbar('danger', 'Napaka pri dodajanju projekta: ' + error.message);
          console.log(error)
        }
      );
    }
    set({ loading: false })
  },
  editing: false,
  setEditing: (editing: boolean) => {
    set({ editing: editing })
  },
  loading: false,
}))
