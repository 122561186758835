import React from "react";
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import Skeleton from "@mui/joy/Skeleton";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import { ColorPaletteProp } from "@mui/joy/styles";
import ListDivider from "@mui/joy/ListDivider";
import {
  ButtonGroup,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemContent,
} from "@mui/joy";
import Button from "@mui/joy/Button";
import { Add, Download, IosShareRounded } from "@mui/icons-material";

import { useTerminsStore } from "../store/terminsStore";
import RowMenu from "./RowMenu";
import { useUserStore } from "../store/userStore";
import { Collapse } from "@mui/material";
import { TransitionGroup } from "react-transition-group";
import { AnimatePresence, easeIn, motion } from "framer-motion";
import IcalExportModal from "./modals/IcalExportModal";

export function TerminsList(props: {
  setOpenModal: React.Dispatch<boolean>;
  setOpenTerminModal: React.Dispatch<boolean>;
}) {
  const project_id = useTerminsStore((state) => state.project_id);
  const termins = useTerminsStore((state) => state.termins);
  const fetchTermins = useTerminsStore((state) => state.fetchTermins);
  const loading = useTerminsStore((state) => state.loading);

  const loggedIn = useUserStore((state) => state.loggedIn);

  // State for exporting iCal file
  const [openIcalExportModal, setOpenIcalExportModal] =
    React.useState<boolean>(false);

  const [disableActions, setDisableActions] = React.useState<boolean>(true);
  const userId = useUserStore((state) => state.userId);
  const owner = useTerminsStore((state) => state.owner);

  React.useEffect(() => {
    setDisableActions(userId !== owner);
  }, [project_id, userId, owner]);

  if (termins.length === 0 && !loading) return <></>;

  return (
    <Box width="100%" sx={{ display: { xs: "block", sm: "none" } }}>
      <Grid
        sx={{
          pt: 2,
          pb: 3,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ButtonGroup>
          <Button
            component="a"
            onClick={() => {
              setOpenIcalExportModal(true);
            }}
            color="primary"
            variant="outlined"
            startDecorator={<IosShareRounded />}
            size="sm"
            sx={{
              boxShadow: "lg",
            }}
          >
            Izvozi v koledar
          </Button>
          <Button
            component="a"
            href={
              "https://hudniki.pythonanywhere.com/endpoints/pdf/" + project_id
            }
            color="primary"
            variant="outlined"
            startDecorator={<Download />}
            size="sm"
            sx={{
              boxShadow: "lg",
            }}
          >
            Izvozi urnik (PDF)
          </Button>
        </ButtonGroup>
      </Grid>
      {loading ? <CircularProgress size="lg" sx={{ mt: 3 }} /> : <></>}
      <List
        size="md"
        sx={{
          "--ListItem-paddingX": 0,
        }}
      >
        {termins.map((termin) => {
          return (
            <>
              <ListItem
                key={termin.id}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <AnimatePresence>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      ease: "anticipate",
                      duration: 0.5,
                    }}
                  >
                    <ListItemContent
                      sx={{ display: "flex", gap: 2, alignItems: "start" }}
                    >
                      <div>
                        <Typography fontWeight={600} gutterBottom>
                          <Skeleton loading={loading}>
                            <Chip
                              variant="soft"
                              size="md"
                              color={
                                {
                                  V: "warning",
                                  N: "primary",
                                  K: "success",
                                }[termin.termin_type] as ColorPaletteProp
                              }
                            >
                              {termin.terminTypeFull}
                            </Chip>
                          </Skeleton>
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            gap: 0.5,
                            mb: 0.5,
                            mt: 1,
                          }}
                        >
                          <Typography level="body-xs">
                            <Skeleton
                              loading={loading}
                              variant="rectangular"
                              width={200}
                              height="1em"
                              sx={{ mb: 1 }}
                            >
                              {termin.date}
                            </Skeleton>
                          </Typography>
                          <Typography level="body-xs">
                            <Skeleton
                              loading={loading}
                              variant="rectangular"
                              width={200}
                              height="1em"
                              sx={{ mb: 1 }}
                            >
                              &bull;
                            </Skeleton>
                          </Typography>
                          <Typography level="body-xs">
                            <Skeleton
                              loading={loading}
                              variant="rectangular"
                              width={200}
                              height="1em"
                              sx={{ mb: 1 }}
                            >
                              {termin.start.slice(0, 5)} -{" "}
                              {termin.end.slice(0, 5)}
                            </Skeleton>
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mb: 1,
                          }}
                        >
                          <Skeleton
                            loading={loading}
                            variant="rectangular"
                            width={200}
                            height="1em"
                            sx={{ mb: 1 }}
                          >
                            <Link
                              href={`https://maps.google.com/?q=${termin.location.replaceAll(" ", "+")}`}
                              target="_blank"
                              level="body-xs"
                            >
                              {termin.location.split(",")[0]}
                            </Link>
                          </Skeleton>
                          <RowMenu
                            termin={termin}
                            setOpenModal={props.setOpenModal}
                          />
                        </Box>
                      </div>
                    </ListItemContent>
                  </motion.div>
                </AnimatePresence>
              </ListItem>
              <ListDivider />
            </>
          );
        })}
      </List>

      <Box
        sx={{
          display: { xs: "flex", sm: "none" },
        }}
      >
        {/*<Button
          disabled={disableActions}
          color="primary"
          startDecorator={<Add />}
          size="sm"
          onClick={() => {
            props.setOpenTerminModal(true);
          }}
          sx={{
            boxShadow: "sm",
          }}
        >
          Dodaj termin
        </Button>*/}
      </Box>

      <IcalExportModal
        open={openIcalExportModal}
        setOpen={setOpenIcalExportModal}
        project_id={project_id}
      />
    </Box>
  );
}
