import * as React from "react";
import Avatar from "@mui/joy/Avatar";
import Chip from "@mui/joy/Chip";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import ButtonGroup from "@mui/joy/ButtonGroup";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import SvgIcon from "@mui/joy/SvgIcon";
import RemoveModal from "./modals/RemoveModal";
import {
  CurrencyYen,
  Delete,
  Edit,
  Gif,
  ShareOutlined,
} from "@mui/icons-material";
import {
  useIndividualProjectStore,
  useProjectsStore,
} from "../store/projectsStore";
import { useTerminsStore } from "../store/terminsStore";
import { Project, Termin, getSlovenianNameOfDay, ProgramEntry } from "../type";
import { useSnackbarStore } from "../store/snackbarStore";
import { motion } from "framer-motion";
import Skeleton from "@mui/joy/Skeleton";
import {
  Grid,
  ListItem,
  Stack,
  List,
  ListDivider,
  ColorPaletteProp,
} from "@mui/joy";
import dayjs, { Dayjs } from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import isoWeek from "dayjs/plugin/isoWeek";
import { useProgramStore } from "../store/programStore";
import { IconButtonRoot } from "@mui/joy/IconButton/IconButton";
import { Organizer, useOrganizersStore } from "../store/organizersStore";

function getNextTermin(termins: Termin[]) {
  const futureTermins = termins.filter((termin) => {
    if (termin.startDayjs !== undefined) {
      return termin.startDayjs > dayjs();
    }
  });
  return futureTermins.reduce((t1, t2) => {
    if (t1.startDayjs !== undefined && t2.startDayjs !== undefined) {
      return t1.startDayjs < t2.startDayjs ? t1 : t2;
    } else {
      return termins[0];
    }
  }, futureTermins[0]);
}

dayjs.extend(updateLocale);
dayjs.extend(isoWeek);

dayjs.updateLocale("en", {
  weekdays: [
    "Nedelja",
    "Ponedeljek",
    "Torek",
    "Sreda",
    "Četrtek",
    "Petek",
    "Sobota",
  ],
});

export default function ProjectPage(props: {
  setOpenProjectModal: React.Dispatch<boolean>;
  disableActions: boolean;
}) {
  const [openRemoveModal, setOpenRemoveModal] = React.useState<boolean>(false);

  const project_id = useTerminsStore((state) => state.project_id);
  const termins = useTerminsStore((state) => state.termins);
  const loadingTermins = useTerminsStore((state) => state.loading);
  const projects = useProjectsStore((state) => state.projects);
  const fetchProjects = useProjectsStore((state) => state.fetchProjects);
  const individualProject = useIndividualProjectStore((state) => state.project);
  const setProject = useIndividualProjectStore((state) => state.setProject);
  const setEditing = useIndividualProjectStore((state) => state.setEditing);
  const program = useProgramStore((state) => state.program);
  const fetchProgram = useProgramStore((state) => state.fetchProgram);
  const loadingProgram = useProgramStore((state) => state.loading);

  const projectOrganizers = useOrganizersStore((state) => state.organizers);

  const [currProject, setCurrProject] = React.useState<Project | null>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [nextTermin, setNextTermin] = React.useState<
    Termin | null | undefined
  >();

  React.useEffect(() => {
    setLoading(true);
    setCurrProject(projects.find((p) => p.id === project_id));
    if (currProject) {
      setLoading(false);
    }
    setNextTermin(getNextTermin(termins));
    fetchProgram();
  }, [project_id, currProject, termins]);

  return (
    <>
      <Grid
        container
        spacing={2}
        columns={{ xs: 4, sm: 8, lg: 12 }}
        sx={{
          flexGrow: 1,
          justifyContent: "space-evenly",
          alignContent: "space-evenly",
        }}
      >
        <Grid xs={4}>
          {/* <Stack spacing={2}> */}
          <Card
            variant="soft"
            invertedColors
            //color="warning"
            sx={{
              boxShadow: "lg",
              flex: 1,
              flexDirection: "column",
            }}
          >
            <CardContent
              sx={{
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                gap: 1,
                m: 1,
                mt: 3,
              }}
            >
              <div>
                <IconButton
                  component="a"
                  onClick={() => {
                    const shareData = {
                      title: currProject?.name,
                      text: `Deli hiper povezavo do projekta '${currProject?.name}'`,
                      url: "https://admin.glasbagrevsvet.si/?id=" + project_id,
                    };
                    if (
                      window.navigator.share &&
                      window.navigator.canShare(shareData)
                    ) {
                      window.navigator.share(shareData);
                    } else {
                      window.navigator.clipboard.writeText(shareData.url);
                    }
                    useSnackbarStore
                      .getState()
                      .setInfo("Povezava kopirana v odložišče!");
                    useSnackbarStore.getState().setColor("success");
                    useSnackbarStore.getState().setOpen(true);
                  }}
                  color="primary"
                  variant="outlined"
                  size="md"
                  sx={{
                    boxShadow: "lg",
                    position: "absolute",
                    top: "0.875rem",
                    right: "0.875rem",
                  }}
                >
                  <ShareOutlined />
                </IconButton>
              </div>
              <Box>
                <Typography level="body-sm">Naslov projekta</Typography>
                <Typography level="h1">
                  <Skeleton loading={loading}>
                    {currProject ? currProject.name : "Project"}
                  </Skeleton>
                </Typography>
              </Box>
              <Box>
                <Typography level="body-sm">Datum zaključka</Typography>
                <Typography level="h4">
                  {currProject?.end_date.split("-")[2]}.
                  {currProject?.end_date.split("-")[1]}.
                  {currProject?.end_date.split("-")[0]}.
                </Typography>
              </Box>
            </CardContent>
            <CardOverflow
              variant="soft"
              color="warning"
              //sx={{ bgcolor: "background.level1" }}
            >
              <CardActions
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Button
                  disabled={props.disableActions}
                  color="primary"
                  variant="solid"
                  startDecorator={<Edit />}
                  size="md"
                  sx={{
                    boxShadow: "sm",
                    borderRadius: "lg",
                  }}
                  onClick={() => {
                    const setIndividualProject = projects.find(
                      (project) => project.id === project_id,
                    );
                    if (setIndividualProject !== undefined) {
                      setEditing(true);
                      setProject(setIndividualProject);
                    }
                    props.setOpenProjectModal(true);
                  }}
                >
                  Uredi projekt
                </Button>

                <Button
                  disabled={props.disableActions}
                  color="danger"
                  startDecorator={<Delete />}
                  size="md"
                  sx={{
                    boxShadow: "sm",
                    borderRadius: "lg",
                  }}
                  onClick={() => {
                    setOpenRemoveModal(true);
                  }}
                >
                  Odstrani projekt
                </Button>
              </CardActions>
            </CardOverflow>
          </Card>
        </Grid>
        <Grid xs={4}>
          {/* <Stack spacing={2}> */}
          <Card sx={{ boxShadow: "lg", height: "100%" }}>
            <CardContent
              sx={{
                alignItems: "center",
                textAlign: "center",
                m: 2,
                spacing: 6,
              }}
            >
              <Box>
                <Typography level="body-sm">Naslednji termin</Typography>
                <Typography level="h2">
                  {nextTermin === null || nextTermin === undefined ? (
                    !loadingTermins ? (
                      "Ni prihajajočih terminov."
                    ) : (
                      <Skeleton loading={true}>
                        "Ni prihajajočih terminov"
                      </Skeleton>
                    )
                  ) : nextTermin.startDayjs !== undefined ? (
                    getSlovenianNameOfDay(nextTermin.startDayjs) +
                    ", " +
                    nextTermin.date
                  ) : (
                    nextTermin.date
                  )}
                </Typography>
                <Typography level="h4">
                  {nextTermin === null || nextTermin === undefined
                    ? ""
                    : `${nextTermin.start.slice(0, 5)} - ${nextTermin.end.slice(0, 5)}`}
                </Typography>
                <Typography level="body-sm">
                  {nextTermin === null || nextTermin === undefined
                    ? ""
                    : nextTermin.location}
                </Typography>
              </Box>
              <Box>
                <Typography level="h2">
                  {nextTermin === null || nextTermin === undefined ? (
                    ""
                  ) : (
                    <Chip
                      variant="soft"
                      size="lg"
                      color={
                        {
                          V: "warning",
                          N: "primary",
                          K: "success",
                        }[nextTermin.termin_type] as ColorPaletteProp
                      }
                    >
                      {nextTermin.terminTypeFull}
                    </Chip>
                  )}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid xs={4}>
          <Card sx={{ boxShadow: "lg" }}>
            <CardContent
              sx={{ alignItems: "center", textAlign: "center", mt: 2 }}
            >
              <Box>
                <Typography level="body-sm">Program</Typography>
                <List size="lg">
                  <Skeleton loading={loadingProgram}>
                    {program.length > 0
                      ? program.map((piece: ProgramEntry) => (
                          <div>
                            <ListItem>{piece.piece}</ListItem>
                            <ListDivider inset="gutter" />
                          </div>
                        ))
                      : "Program še ni določen."}
                  </Skeleton>
                  {/* <ListItem>Lorem ipsum dolor sit amet.</ListItem>
                                    <ListDivider inset="gutter" />
                                    <ListItem>Lorem ipsum dolor sit amet.</ListItem> */}
                </List>
              </Box>
            </CardContent>
          </Card>
          {/* </Stack> */}
        </Grid>

        <Grid xs={4}>
          <Card sx={{ boxShadow: "lg" }}>
            <CardContent
              sx={{ alignItems: "center", textAlign: "center", mt: 2 }}
            >
              <Box>
                <Typography level="body-sm">Organizatorji</Typography>
                <List size="lg">
                  <Skeleton loading={loadingProgram}>
                    {projectOrganizers.length > 0
                      ? projectOrganizers.map((organizer: Organizer) => (
                          <div>
                            <ListItem>
                              {organizer.name}: {organizer.email}
                            </ListItem>
                            <ListDivider inset="gutter" />
                          </div>
                        ))
                      : "Organizatorji niso na voljo."}
                  </Skeleton>
                  {/* <ListItem>Lorem ipsum dolor sit amet.</ListItem>
                    <ListDivider inset="gutter" />
                    <ListItem>Lorem ipsum dolor sit amet.</ListItem> */}
                </List>
              </Box>
            </CardContent>
          </Card>
          {/* </Stack> */}
        </Grid>
      </Grid>
      <RemoveModal open={openRemoveModal} setOpen={setOpenRemoveModal} />
    </>
  );
}
