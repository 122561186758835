import { Button, CssBaseline, CssVarsProvider, DialogContent, DialogTitle, FormControl, FormLabel, Input, Modal, ModalDialog, Select, Option, Stack, ModalClose } from "@mui/joy";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import React from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { useIndividualTerminStore, useTerminsStore } from "../../store/terminsStore";
import { useShallow } from "zustand/react/shallow";

// importing material ui styles
import {
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    experimental_extendTheme as extendMaterialTheme,
    THEME_ID
} from "@mui/material/styles";

import { createTermin } from "../../type";
import { useUserStore } from "../../store/userStore";

import customParseFormat from 'dayjs/plugin/customParseFormat'

const materialTheme = extendMaterialTheme();
dayjs.extend(customParseFormat)

export function TerminModal(props: {open: boolean, setOpen: React.Dispatch<boolean>}) {

  const { termin, setTermin, clearTermin, postTermin, updateTemin, loading, editing, setEditing } = useIndividualTerminStore(
    useShallow((state) => ({
      termin: state.termin,
      setTermin: state.setTermin,
      clearTermin: state.clearTermin,
      postTermin: state.postTermin,
      updateTemin: state.updateTermin,
      loading: state.loading,
      editing: state.editing,
      setEditing: state.setEditing,
    })),
  )

  const project_id = useTerminsStore((state) => (state.project_id))

  const token = useUserStore((state) => state.token)

  const [date, setDate] = React.useState<Dayjs | null>(dayjs("2024-09-09T00:00"));
  const [start, setStart] = React.useState<Dayjs | null>(dayjs("2024-09-09T10:00"))
  const [end, setEnd] = React.useState<Dayjs | null>(dayjs("2024-09-09T11:00"));
  const [location, setLocation] = React.useState<string>("Neznano");
  const [type, setType] = React.useState<string>("termin_type");


  React.useLayoutEffect(() => {
    setLocation("Neznano")
    if (editing) {
      setType(termin.termin_type)
      setLocation(termin.location)
      setDate(dayjs(termin.date, "DD.MM.YYYY"))
      console.log("Termin start: " + termin.start)
      console.log(start)

      setStart(dayjs(termin.start, 'HH:mm:ss'))
      setEnd(dayjs(termin.end, 'HH:mm:ss'))
      console.log("Termin date: "+ termin.date)
            console.log(date)
      console.log("Termin end: " + termin.end)
            console.log(end)
      if (!start?.isValid()) {
        console.log("start is invalid. trying to set once more")
        setStart(dayjs(termin.start, 'HH:mm:ss', true))
      }
      if (!end?.isValid()) {
        console.log("end is invalid. trying to set once more")
        setEnd(dayjs(termin.end, 'HH:mm:ss', true))
      }
    }} , [props.open])

  const [stringDate, setStringDate] = React.useState<string>("2024-09-09");
  const [stringStart, setStringStart] = React.useState<string>("00:00");
  const [stringEnd, setStringEnd] = React.useState<string>("00:00");

  React.useEffect(() => {
      date != null ? setStringDate(date.format("YYYY-MM-DD")) : setStringDate("null");
      start != null ? setStringStart(start.format("HH:mm")) : setStringStart("null");
      end != null ? setStringEnd(end.format("HH:mm")) : setStringEnd("null");
  }, [date, start, end]);

  React.useEffect(() => {
    if (!loading) {
      props.setOpen(false)
    }
  }, [loading])

  return (
    <React.Fragment>
      <MaterialCssVarsProvider theme={{ [THEME_ID]: materialTheme }}>
        <Modal
            open={props.open}
            onClose={() => {
              clearTermin()
              setEditing(false)
              props.setOpen(false)
            }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
        >
                <ModalDialog>
                    <ModalClose />
                    <DialogTitle>Dodaj termin</DialogTitle>
                        <DialogContent>Izpolni vse podatke</DialogContent>
                            <form
                                onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                                    event.preventDefault();
                                    setTermin(createTermin(termin.id, project_id, type, stringDate, stringStart, stringEnd, location))
                                    if(editing) { updateTemin() } else { postTermin() }
                                    setEditing(false)
                                }}
                            >
                                <Stack spacing={2}>
                                    <FormControl>
                                        <Select
                                            placeholder="izberi tip termina"
                                            defaultValue={
                                              (editing) ? type : null
                                            }
                                            onChange={(
                                                event: React.SyntheticEvent | null,
                                                newValue: string | null,) => {
                                                    newValue != null ? setType(newValue) : setType("V");
                                            }}>
                                            <Option value="V">Vaja</Option>
                                            <Option value="N">Nastop</Option>
                                            <Option value="K">Koncert</Option>
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                              label="Izberi datum"
                                              defaultValue={(editing) ? date : null}
                                              onChange={(newValue) => {
                                                setDate(newValue)
                                                //setEnd(newValue)
                                            }}
                                            format="DD.MM.YYYY"
                                            />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </FormControl>
                                    <FormControl>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                         <DemoContainer components={['TimeField']}>
                                            <TimeField
                                                label="Začetek"
                                                defaultValue={(editing) ? dayjs(termin.start, "HH:mm:ss") : null}
                                                onChange={(newValue) => {
                                                  console.log(start)
                                                  setStart(newValue)
                                                }}
                                                format="HH:mm"
                                            />
                                            </DemoContainer>
                                        </LocalizationProvider>

                                    </FormControl>
                                    <FormControl>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                          <DemoContainer components={['TimeField']}>
                                            <TimeField
                                                label="Konec"
                                                defaultValue={(editing) ? dayjs(termin.end, "HH:mm:ss") : null}
                                                onChange={(newValue) => setEnd(newValue)}
                                                format="HH:mm"
                                            />
                                            </DemoContainer>
                                        </LocalizationProvider>

                                    </FormControl>
                            <FormControl>
                                <FormLabel>Lokacija</FormLabel>
                                <Input
                                  required
                                  placeholder="Lokacija"
                                  value={(editing) ? location : undefined}
                                  onChange={(event) => {setLocation(event.target.value)}}
                                  />
                            </FormControl>
                            <Button
                                loading={loading}
                                type="submit">
                                {(editing) ? "Posodobi" : "Dodaj"}
                                </Button>
                        </Stack>
                    </form>
                </ModalDialog>
        </Modal>
        </MaterialCssVarsProvider>
    </React.Fragment>
  )
}
