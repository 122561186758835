import { create } from 'zustand'

type SnackbarStore = {
  open: boolean
  setOpen: (newOpen: boolean) => void
  color: "neutral" | "danger" | "success" | "warning"
  setColor: (newColor: "neutral" | "danger" | "success" | "warning") => void
  info: string
  setInfo: (newInfo: string) => void

  showSnackbar: (showColor: "neutral" | "danger" | "success" | "warning", showInfo: string) => void
}

export const useSnackbarStore = create<SnackbarStore>()((set) => ({
  open: false,
  setOpen: (newOpen: boolean) => {
    set({ open: newOpen })},
  color: "neutral",
  setColor: (newColor: "neutral" | "danger" | "success" | "warning") => {
    set({ color: newColor })},
  info: "No info to show",
  setInfo: (newInfo: string) => {
    set({ info: newInfo })
  },

  showSnackbar(showColor, showInfo) {
    set({ open: true, color: showColor, info: showInfo })
  },
}))
