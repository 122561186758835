import { create } from "zustand";
import { ProgramEntry } from "../type";
import { URL } from "../globalConstants";
import axios from "axios";
import { useTerminsStore } from "./terminsStore";

const PROGRAM_ENTRY_URL = URL + "/piecechoices/";

type ProgramStore = {
  program: ProgramEntry[];
  fetchProgram: () => void;
  postProgramEntry: () => void;
  patchProgramEntry: () => void;
  deleteProgramEntry: () => void;
  loading: boolean;
};

export const useProgramStore = create<ProgramStore>()((set, get) => ({
  program: [],
  fetchProgram: () => {
    set({ loading: true });
    axios
      .get(
        PROGRAM_ENTRY_URL + "?project=" + useTerminsStore.getState().project_id,
      )
      .then((response) => {
        set({ program: response.data });
        set({ loading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  },
  postProgramEntry: () => {},
  patchProgramEntry: () => {},
  deleteProgramEntry: () => {},
  loading: false,
}));
