import { useContext } from "react";
import axios from "axios";
import { Button, CssBaseline, CssVarsProvider, DialogContent, DialogTitle, FormControl, FormLabel, Input, Modal, ModalDialog, Select, Option, Stack, ModalClose, Typography } from "@mui/joy";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import React from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Project, createProject } from "../../type";

import {
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    experimental_extendTheme as extendMaterialTheme,
    THEME_ID
} from "@mui/material/styles";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";
import { useIndividualProjectStore, useProjectsStore } from "../../store/projectsStore";
import { useShallow } from "zustand/react/shallow";
import { useIndividualTerminStore } from "../../store/terminsStore";
import { warn } from "console";
import { InsertDriveFile } from "@mui/icons-material";
import { Switch } from "@mui/base";

const materialTheme = extendMaterialTheme();



export function ProjectModal(props: { open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>}) {

  const { addProject } = useProjectsStore(useShallow((state) => ({
    addProject: state.addProject,
  })))

  const { individualProject, setIndividualProject, modalAction, editing, setEditing, loading } = useIndividualProjectStore(useShallow((state) => ({
    individualProject: state.project,
    setIndividualProject: state.setProject,
    modalAction: state.modalAction,
    editing: state.editing,
    setEditing: state.setEditing,
    loading: state.loading,
  })))

    const [project, setProject] = React.useState<Project | null>(null);

    // States for the Create termin function
    const [end, setEnd] = React.useState<Dayjs | null>(dayjs());
    const [name, setName] = React.useState<string>("Brez imena");
    const [publicView, setPublicView] = React.useState<boolean>(false);
    const [stringEnd, setStringEnd] = React.useState<string>("HH:mm");

    React.useLayoutEffect(() => {
      if (editing && individualProject !== null) {
        setPublicView(individualProject.public_view)
        console.log(publicView)
        setName(individualProject.name)
        setEnd(dayjs(individualProject.end_date, "YYYY-MM-DD"))
        if (!end?.isValid()) {
          console.log("end is invalid. trying to set once more")
          setEnd(dayjs(individualProject.end_date, "YYYY-MM-DD", true))
        }
      } else {
        setName("Ime")
        setEnd(dayjs())
        setPublicView(false)
      }
      console.log(individualProject)
    } , [props.open])

    React.useEffect(() => {
      if (end !== null) {
        const projectToSet: Project = {
          id: ((individualProject === null) ? 0 : individualProject.id),
          name: name,
          owner: 0,
          end_date: end.format("YYYY-MM-DD"),
          public_view: publicView,
        }
        setIndividualProject(projectToSet)
      }
    }, [name, end, publicView])

    return (
    <>
            <MaterialCssVarsProvider theme={{ [THEME_ID]: materialTheme }}>
                <CssVarsProvider>
                    <CssBaseline />
        <Modal
            open={props.open}
            onClose={() => {
              setEditing(false)
              props.setOpen(false)
            }}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
                <ModalDialog>
                    <ModalClose />
                    <DialogTitle>Dodaj projekt</DialogTitle>
                        <DialogContent>Izpolni podatke</DialogContent>
                            <form
                                onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                                    event.preventDefault();
                                    modalAction()
                                    props.setOpen(false);
                                }}
                            >
                                <Stack spacing={2}>
                                <FormControl>
                            <FormLabel>Naslov projekta</FormLabel>
                                <Input
                                  required
                                  defaultValue={name}
                                  onChange={(event) => {setName(event.target.value)}}
                                  />
                            </FormControl>
                                    <FormControl>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                            label="Datum zaključka"
                                            value={end}
                                            onChange={(newValue) => {
                                                setEnd(newValue)
                                            }}
                                            format="DD.MM.YYYY"
                                            />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </FormControl>
                                    <Typography>
                                      Javna vidnost projekta
                                      <Switch
                                      checked={publicView}
                                      onChange={(e) => {
                                        setPublicView( !publicView )
                                      }}
                                      />
                                    </Typography>
                            <Button
                                loading={loading}
                                type="submit">
                                  {(editing) ? "Posodobi" : "Dodaj"}
                                </Button>
                        </Stack>
                    </form>
                </ModalDialog>
        </Modal>
        </CssVarsProvider>
        </MaterialCssVarsProvider>
    </>
  );
}
