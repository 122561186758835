import { Button, DialogTitle, Modal, ModalDialog } from "@mui/joy";
import { SECTION_TYPE_GRANULARITY } from "@mui/x-date-pickers/internals/utils/getDefaultReferenceDate";
import axios from "axios";
import React from "react";
import { Delete } from "@mui/icons-material";
import { redirect, useNavigate } from "react-router-dom";
import { useProjectsStore } from "../../store/projectsStore";
import { useTerminsStore } from "../../store/terminsStore";
import { useShallow } from "zustand/react/shallow";

export default function RemoveModal(props: {open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>> }) {

  const { removeProject, loading } = useProjectsStore(useShallow((state) => ({
    removeProject: state.removeProject,
    loading: state.loading,
  })))

  const { project_id, project_name } = useTerminsStore(useShallow((state) => ({
      project_id: state.project_id,
      project_name: state.project_name,
    }
  )))

  const navigate = useNavigate();

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {props.setOpen(false)}}
      >
        <ModalDialog>
        <form onSubmit={(e) => {
          e.preventDefault()
          removeProject(project_id)
          props.setOpen(false)
        }}>
          <DialogTitle>
            Želite izbrisati projekt '{project_name}'?
          </DialogTitle>
          <Button
            color="danger"
            startDecorator={<Delete />}
            size="sm"
            type="submit"
            loading={loading}
          >
              Potrdi
          </Button>
          </form>
        </ModalDialog>
      </Modal>
    </>
  )
}
