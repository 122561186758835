import { Warning } from "@mui/icons-material";
import { DialogContent, DialogTitle, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import { Box } from "@mui/system";

export default function IcalExportModal (props: {open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>, project_id: number}) {

  return (
    <Modal
            open={props.open}
            onClose={() => {
              props.setOpen(false)
            }}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
                <ModalDialog>
                    <ModalClose />
                    <DialogTitle
                    level="title-lg"
                    sx={{justifyContent: 'center', mb: 2}}

                    ><Typography level="h2">Poveži urnik s svojim osebnim koledarjem</Typography></DialogTitle>
                        <DialogContent
                          sx={{
                            justifyContent: 'center',
                            overflowX: 'hidden',
                          }}
                        >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                          }}
                        >
                        <Typography
                          level="body-lg"
                                                    sx={{justifyContent: 'center'}}

                        >
                          Urnik projekta lahko povežeš s svojim osebnim koledarjem.</Typography>
                          <Typography level="body-lg">Na iOS napravah preprosto klikni na spodnjo povezavo.</Typography>
                          <Typography level="body-lg">Če želiš urnik dodati v svoj Google račun, kopiraj spodnjo povezavo in odpri Google koledar.
                            Levo spodaj, poleg napisa "Ostali koledarji" [Other calendars],
                          pritisni gumb z znakom "+". Spodnjo povezavo prilepi v polje in potrdi. </Typography>

                          <Typography
                          level="body-lg"
                            component="a"
                            href={`https://hudniki.pythonanywhere.com/endpoints/feed/${props.project_id}/feed.ics`}
                          >
                            {`https://hudniki.pythonanywhere.com/endpoints/feed/${props.project_id}/feed.ics`}
                          </Typography>

                          <Typography
                            variant="soft"
                            color="danger"
                            startDecorator={<Warning/>}
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              padding: 2,
                              maxWidth: '70%',
                            }}
                          >
                          POZOR: lahko se zgodi, da se dogodki v Google koledarju posodobijo šele 24 ur po vnosu, zato ti priporočamo, da slediš tudi posodobitvam na strani admin.glasbagrevsvet.si.
                            </Typography>
                          </Box>
                        </DialogContent>
                </ModalDialog>
        </Modal>

  )
}
