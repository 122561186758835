import { FormControl, Input } from "@mui/base"
import { Modal, Button, Autocomplete, DialogContent, DialogTitle, FormLabel, ModalDialog, ModalClose } from "@mui/joy"
import { Stack } from "@mui/system"
import axios from "axios"
import React from "react"
import { Participant, useParticipationStore } from "../../store/participations"
import { useTerminsStore } from "../../store/terminsStore"

export default function ParticipantModal(props: {open: boolean, setOpen: React.Dispatch<boolean>}) {

  const [participants, setParticipants] = React.useState<Participant[]>([])
  const [emails, setEmails] = React.useState<String[]>([])
  const [email, setEmail] = React.useState<String | null>("")

  const project_id = useTerminsStore((state) => state.project_id)
  const postNewParticipation = useParticipationStore((state) => state.postNewPaticipation)
  const projectParticipants = useParticipationStore((state) => state.participants)

  React.useEffect(() => {
    axios.get(`https://hudniki.pythonanywhere.com/endpoints/participants`)
          .then((res) => {
            setParticipants(res.data)
        }).catch((err) => console.log(err))
  }, [])

  React.useEffect(() => {
    console.log("Participants filtered:")
    console.log(participants.filter((p) => !projectParticipants.some((p1) => p.id === p1.id)))
    setEmails(participants
              .filter((p) => !projectParticipants
                      .some((p1) => p.id === p1.id))
                      .map((p) => p.email))
  }, [participants, projectParticipants])

  return (<>
      <Modal
      aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={props.open}
        onClose={() => props.setOpen(false)}>
      <ModalDialog>
      <ModalClose variant="plain" sx={{ m: 1 }} />
          <DialogTitle>Dodaj novega udeleženca</DialogTitle>
          <DialogContent>Izberi enega izmed potrjenih elektronskih naslovov</DialogContent>
          <form
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              const participant_id = participants.find((p) => p.email === email)?.id
              if (participant_id !== undefined) {
                postNewParticipation(participant_id, project_id)
              }
              props.setOpen(false);
            }}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Elektronski naslov</FormLabel>
                <Autocomplete
                  placeholder="Elektronski naslov"
                  options={emails}
                  sx={{ width: 300 }}
                  onChange={(event, newValue) => {
                    setEmail(newValue);
                  }}
                  noOptionsText="Ni več potrjenih naslovov"
                  />
              </FormControl>
              <Button type="submit">Dodaj</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
          </>)
}
