import React from "react";
import logo from "./logo.svg";
import "./App.css";

import Home from "./Home";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { Login } from "./components/Login";

import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AnimatePresence } from "framer-motion";
import axiosRetry from "axios-retry";
import axios from "axios";
import MyCalendar from "./components/Calendar";
import { useTerminsStore } from "./store/terminsStore";
import { useUserStore } from "./store/userStore";
import { useProjectsStore } from "./store/projectsStore";

const materialTheme = materialExtendTheme();

function App() {
  const user = useUserStore((state) => state.userId);
  const checkIfLoggedIn = useUserStore((state) => state.checkIfLoggedIn);

  const projects = useProjectsStore((state) => state.projects);

  const setProjectId = useTerminsStore((state) => state.setProjectId);
  const setProjectName = useTerminsStore((state) => state.setProjectName);

  React.useEffect(() => {
    checkIfLoggedIn();
    if (projects.length !== 0 && user !== -1) {
      const project = projects[0];
      setProjectId(project.id);
      setProjectName(project.name);
    }
  });

  return (
    <>
      <div>
        <AnimatePresence mode="wait">
          <MaterialCssVarsProvider
            theme={{ [MATERIAL_THEME_ID]: materialTheme }}
          >
            <JoyCssVarsProvider>
              <CssBaseline enableColorScheme />
              <BrowserRouter>
                <Routes>
                  <Route path="/">
                    <Route index element={<Home />} />
                    <Route path="login" element={<Login />} />
                    <Route path="calendar" element={<MyCalendar />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </JoyCssVarsProvider>
          </MaterialCssVarsProvider>
        </AnimatePresence>
      </div>
    </>
  );
}

export default App;
